import { configureStore, createSlice } from '@reduxjs/toolkit';

const initialState = { counter: 0 };

const slice = createSlice({
  name: 'counter',
  initialState: initialState,
  reducers: {
    increment(state) {
      state.counter = state.counter + 1;
    },
    decrement(state) {
      state.counter = state.counter - 1;
    },
    increase(state, action) {
      state.counter = state.counter + action.payload;
    }
  }
});

export const counterActions = slice.actions;
export const reducer = slice.reducer;
export default slice;
