import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axiosIn';
import logger from 'src/utils/logger';

const initialState = {
  catalogs: []
};

const slice = createSlice({
  name: 'catalogs',
  initialState,
  reducers: {
    getCatalogs(state, action) {
      const { catalogs } = action.payload;
      state.catalogs = catalogs;
    },
    addCatalog(state, action) {
      state.catalog = [action.payload];
    },
    getCatalog(state, action) {
      const { catalogs } = action.payload;
      state.catalogs = catalogs;
    }
  }
});
export const { addCatalogs, updateCatalog } = slice.actions;
export const reducer = slice.reducer;

export const getCatalogs = () => dispatch => {
  (async () => {
    await axios.get(`${process.env.REACT_APP_BE_PIM_URL}/catalog`).then(res => {
      let obj = { catalogs: JSON.parse(res.data.result) };
      dispatch(slice.actions.getCatalogs(obj));
    });
  })().catch(err => {
    logger(err);
  });
};

export const getCatalog = id => dispatch => {
  (async () => {
    await axios
      .get(`${process.env.REACT_APP_BE_PIM_URL}/catalog/${id}`)
      .then(res => {
        let obj = { catalogs: JSON.parse(res.data.result) };
        dispatch(slice.actions.getCatalog(obj));
      });
  })().catch(err => {
    logger(err);
  });
};

export const editCatalog = (data, id) => dispatch => {
  (async () => {
    await axios
      .put(`${process.env.REACT_APP_BE_PIM_URL}/catalog/${id}`, data)
      .then(res => {
        logger('catalog posted');
      });
  })().catch(err => {
    console.error(err);
  });
};

export default slice;
