import jwtDecode from 'jwt-decode';

const isValidToken = (accessToken) => {
  if (accessToken) {
    const currentTime = Date.now() / 1000;
    const decoded = jwtDecode(accessToken);
    return decoded.exp > currentTime;
  }
};

export default isValidToken;

