import { createSlice } from '@reduxjs/toolkit';
import _, { isUndefined } from 'lodash';
import { number } from 'prop-types';
import axios from 'src/utils/axiosIn';
import logger from 'src/utils/logger';

const initialState = {
  clients: [],
  total: 0
};

const slice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    getClients(state, action) {
      const { clients, total } = action.payload;
      state.clients = clients;
      state.total = total;
    },
    addClient(state, action) {
      state.clients = [action.payload];
    },
    getClient(state, action) {
      const { clients } = action.payload;
      state.clients = clients;
    }
  }
});
export const { addClient } = slice.actions;
export const reducer = slice.reducer;

export const getClients = (page, limit, clientTypeIndex) => dispatch => {
  var body = {
    page,
    limit
  };
  if (!isUndefined(clientTypeIndex) && clientTypeIndex !== 0)
    body.is_business = clientTypeIndex == 1 ? false : true;

  (async () => {
    await axios
      .post(`${process.env.REACT_APP_BE_CLIENT_URL}/getallclient`, body)
      .then(res => {
        let obj = JSON.parse(
          JSON.stringify({ clients: [...res.data.data], total: res.data.total })
        );
        dispatch(slice.actions.getClients(obj));
      });
  })().catch(err => {
    logger(err);
  });
};

export const getActiveClients = (
  page,
  limit,
  clientTypeIndex,
  n
) => dispatch => {
  var body = {
    page,
    limit,
    is_activated: n
  };
  if (!isUndefined(clientTypeIndex) && clientTypeIndex !== 0)
    body.is_business = clientTypeIndex == 1 ? false : true;

  (async () => {
    await axios
      .post(`${process.env.REACT_APP_BE_CLIENT_URL}/getallclient`, body)
      .then(res => {
        let obj = JSON.parse(
          JSON.stringify({ clients: [...res.data.data], total: res.data.total })
        );
        dispatch(slice.actions.getClients(obj));
      });
  })().catch(err => {
    logger(err);
  });
};

export const getQueryClients = (page, limit, n, active) => dispatch => {
  const body = {
    page,
    limit,
    search: n,
    is_activated: active
  };

  (async () => {
    await axios
      .post(`${process.env.REACT_APP_BE_CLIENT_URL}/getallclient`, body)
      .then(res => {
        let obj = JSON.parse(
          JSON.stringify({ clients: [...res.data.data], total: res.data.total })
        );
        dispatch(slice.actions.getClients(obj));
      });
  })().catch(err => {
    logger(err);
  });
};

export const editClient = (data, id) => dispatch => {
  (async () => {
    await axios
      .put(`${process.env.REACT_APP_BE_CLIENT_URL}/${id}`, data)
      .then(res => {
        logger('Client Updated Successfully');
      });
  })().catch(err => {
    logger(err);
  });
};

export default slice;
