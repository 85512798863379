import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as organizationReducer } from 'src/slices/organization';
import { reducer as clientReducer } from 'src/slices/client';
import { reducer as employeeReducer } from 'src/slices/employee';
// import { reducer as servicesReducer } from 'src/slices/service';
import { reducer as tagsReducer } from 'src/slices/tags';
import { reducer as bicycleAttributesReducer } from 'src/slices/bicycleAttribute';
import { reducer as orgTypeReducer } from 'src/slices/orgType';
import { reducer as catalogReducer } from 'src/slices/catalog';
import { reducer as locationReducer } from 'src/slices/location';
import { reducer as customerReducer } from 'src/slices/customers';
import { reducer as administratorsReducer } from 'src/slices/administrator';
import { reducer as bicycleTypeReducer } from 'src/slices/bicType';
import { reducer as bicycleFrameReducer } from 'src/slices/bicFrame';
import { reducer as counterReducer } from 'src/slices/counter';
import productDataSlice from 'src/slices/getProducts';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  organization: organizationReducer,
  client: clientReducer,
  employee: employeeReducer,
  // services: servicesReducer,
  tags: tagsReducer,
  bicycleAttributes: bicycleAttributesReducer,
  orgType: orgTypeReducer,
  catalogs: catalogReducer,
  locations: locationReducer,
  customers: customerReducer,
  administrators: administratorsReducer,
  bicycleType: bicycleTypeReducer,
  bicycleFrame: bicycleFrameReducer,
  counter: counterReducer,
  productDataSlice: productDataSlice
});

export default rootReducer;
