/* eslint-disable no-use-before-define */
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  makeStyles,
  Typography
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import { v4 as uuidv4 } from 'uuid';
import NavItem from './NavItem';

const sections = [
  {
    items: [
      {
        id: uuidv4(),
        title: 'Dashboard',
        icon: 'https://assets.it22.nl/tda/admin/sidebar/DashboardIcon.svg',
        href: '/admin/dashboard',
        isParent: true
      },
      {
        id: uuidv4(),
        title: 'CRM',
        icon: 'https://assets.it22.nl/tda/admin/sidebar/ContactIcon.svg',
        href: '',
        links: [
          '/admin/client',
          '/admin/organization',
          '/admin/tag',
          '/admin/organization-type',
          '/admin/employee'
        ],
        items: [
          {
            id: uuidv4(),
            title: 'Clients',
            href: '/admin/client'
          },
          {
            id: uuidv4(),
            title: 'Organizations',
            href: '/admin/organization'
          },
          {
            id: uuidv4(),
            title: 'Organization Types',
            href: '/admin/organization-type'
          },
          {
            id: uuidv4(),
            title: 'Tags',
            href: '/admin/tag'
          },
          {
            id: uuidv4(),
            title: 'Employees',
            href: '/admin/employee'
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        id: uuidv4(),
        title: 'Bookings Overview',
        icon: 'https://assets.it22.nl/tda/admin/sidebar/EventIcon.svg',
        href: '/admin/bookings-overview',
        isParent: true
      }
    ]
  },
  {
    items: [
      {
        id: uuidv4(),
        title: 'Tours',
        icon: 'https://assets.it22.nl/tda/admin/sidebar/EventIcon.svg',
        href: '',
        links: ['/admin/event_renting', '/admin/tour'],
        items: [
          {
            id: uuidv4(),
            title: 'Bookings',
            href: '/admin/event_renting'
          },
          {
            id: uuidv4(),
            title: 'Tours',
            href: '/admin/tour'
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        id: uuidv4(),
        title: 'Clinics',
        icon: 'https://assets.it22.nl/tda/admin/sidebar/ClinicsIcon.svg',
        href: '',
        links: ['/admin/clinic_booking', '/admin/clinic'],
        items: [
          {
            id: uuidv4(),
            title: 'Bookings',
            href: '/admin/clinic_booking'
          },
          {
            id: uuidv4(),
            title: 'Clinics',
            href: '/admin/clinic'
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        id: uuidv4(),
        title: 'Rentouts',
        icon: 'https://assets.it22.nl/tda/admin/sidebar/BikeRentingIcon.svg',
        href: '',
        links: [
          '/admin/bicycle_renting',
          '/admin/bicycle_type',
          '/admin/bicycle_frame',
          '/admin/bicycle_attribute',
          '/admin/bicycle_attribute_detail'
        ],
        items: [
          {
            id: uuidv4(),
            title: 'Bicycles',
            href: '',
            isThreeLevel: true,
            links: [
              '/admin/bicycle_renting',
              '/admin/bicycle_type',
              '/admin/bicycle_frame',
              '/admin/bicycle_attribute',
              '/admin/bicycle_attribute_detail'
            ],
            items: [
              {
                id: uuidv4(),
                title: 'Rentings',
                href: '/admin/bicycle_renting'
              },
              {
                id: uuidv4(),
                title: 'Types',
                href: '/admin/bicycle_type'
              },
              {
                id: uuidv4(),
                title: 'Frames',
                href: '/admin/bicycle_frame'
              },
              {
                id: uuidv4(),
                title: 'Attributes',
                href: '/admin/bicycle_attribute'
              },
              {
                id: uuidv4(),
                title: 'Attribute Details',
                href: '/admin/bicycle_attribute_detail'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        id: uuidv4(),
        title: 'Reports',
        icon: 'https://assets.it22.nl/tda/admin/sidebar/BikeRentingIcon.svg',
        href: '/admin/reports',
        isParent: true
      }
    ]
  },
  {
    items: [
      {
        id: uuidv4(),
        title: 'POS',
        icon: 'https://assets.it22.nl/tda/admin/sidebar/DashboardIcon.svg',
        href: '/admin/pos',
        isParent: true
      }
    ]
  },
  {
    items: [
      {
        id: uuidv4(),
        title: 'e-Commerce',
        icon: 'https://assets.it22.nl/tda/admin/sidebar/ContactIcon.svg',
        href: '',
        links: ['/admin/product-order'],
        items: [
          //   {
          //     id: uuidv4(),
          //     title: 'Dashboard',
          //     href: '/admin/e-commerce/dashboard'
          //   },
          {
            id: uuidv4(),
            title: 'Orders',
            href: '/admin/product-order'
          }
        ]
      }
    ]
  },

  {
    items: [
      {
        id: uuidv4(),
        title: 'Product Manager',
        icon:
          'https://assets.it22.nl/tda/admin/sidebar/BookingOverviewIcon.svg',
        href: '',
        links: [
          '/admin/catalog',
          '/admin/product',
          '/admin/product-attribute',
          '/admin/product-attribute-detail',
          '/admin/product-category',
          '/admin/product-tag',
          '/admin/product-supplier',
          '/admin/product-tax',
          '/admin/location',
          '/admin/brand',
          '/admin/supplier',
          '/admin/product-stock',
          '/admin/product-order'
        ],
        items: [
          {
            id: uuidv4(),
            title: 'Catalogs',
            href: '/admin/catalog'
          },
          {
            id: uuidv4(),
            title: 'Products',
            href: '/admin/product'
          },
          {
            id: uuidv4(),
            title: 'Product Attributes',
            href: '/admin/product-attribute'
          },
          {
            id: uuidv4(),
            title: 'Product Attributes Detail',
            href: '/admin/product-attribute-detail'
          },
          {
            id: uuidv4(),
            title: 'Product Categories',
            href: '/admin/product-category'
          },
          {
            id: uuidv4(),
            title: 'Product Tags',
            href: '/admin/product-tag'
          },
          {
            id: uuidv4(),
            title: 'Brand',
            href: '/admin/brand'
          },
          {
            id: uuidv4(),
            title: 'Suppliers',
            href: '/admin/supplier'
          },
          {
            id: uuidv4(),
            title: 'Taxes',
            href: '/admin/product-tax'
          },
          {
            id: uuidv4(),
            title: 'Locations',
            href: '/admin/location'
          },
          {
            id: uuidv4(),
            title: 'Product Stock',
            href: '/admin/product-stock'
          }
        ]
      }
    ]
  },
  // {
  //   items: [
  //     {
  //       id: uuidv4(),
  //       title: 'Invoicing',
  //       icon:
  //         'https://assets.it22.nl/tda/admin/sidebar/BookingOverviewIcon.svg',
  //       href: '',
  //       links: ['/admin/invoice', '/admin/transaction'],
  //       items: [
  //         {
  //           id: uuidv4(),
  //           title: 'Invoices',
  //           href: '/admin/invoice'
  //         },
  //         {
  //           id: uuidv4(),
  //           title: 'Transactions',
  //           href: '/admin/transaction'
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   items: [
  //     {
  //       id: uuidv4(),
  //       title: 'E-POS',
  //       icon:
  //         'https://assets.it22.nl/tda/admin/sidebar/BookingOverviewIcon.svg',
  //       href: '',
  //       links: ['/admin/epos/invoice'],
  //       items: [
  //         {
  //           id: uuidv4(),
  //           title: 'Dashboard',
  //           href: '/admin/epos/dashboard'
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    items: [
      {
        id: uuidv4(),
        title: 'Users',
        icon: 'https://assets.it22.nl/tda/admin/sidebar/UsersIcon.svg',
        href: '/admin/administrator',
        links: ['/admin/administrator', '/admin/customer'],
        items: [
          {
            id: uuidv4(),
            title: 'Administrators',
            href: '/admin/administrator'
          },
          {
            id: uuidv4(),
            title: 'Customers',
            href: '/admin/customer'
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        id: uuidv4(),
        title: 'Service',
        icon: 'https://assets.it22.nl/tda/admin/sidebar/UsersIcon.svg',
        href: '/admin/appointment',
        links: [
          '/admin/bike-fit-appointment',
          '/admin/bike-fit-service',
          '/admin/appointment',
          '/admin/service',
          '/admin/service-category'
        ],
        items: [
          {
            id: uuidv4(),
            title: 'Bike Fit',
            icon:
              'https://assets.it22.nl/tda/admin/sidebar/BikeUpgradeIcon.svg',
            href: '',
            links: ['/admin/bike-fit-appointment', '/admin/bike-fit-service'],
            items: [
              {
                id: uuidv4(),
                title: 'Appointments',
                href: '/admin/bike-fit-appointment'
              },
              {
                id: uuidv4(),
                title: 'Services',
                href: '/admin/bike-fit-service'
              }
            ]
          },
          {
            id: uuidv4(),
            title: 'Maintenance',
            icon:
              'https://assets.it22.nl/tda/admin/sidebar/BikeMaintenanceIcon.svg',
            href: '',
            links: [
              '/admin/appointment',
              '/admin/service',
              '/admin/service-category'
            ],
            items: [
              {
                id: uuidv4(),
                title: 'Appointments',
                href: '/admin/appointment'
              },
              {
                id: uuidv4(),
                title: 'Services',
                href: '/admin/service'
              },
              {
                id: uuidv4(),
                title: 'Service Category',
                href: '/admin/service-category'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    items: [
      {
        id: uuidv4(),
        title: 'Settings',
        icon: 'https://assets.it22.nl/tda/admin/sidebar/SettingIcon.svg',
        href: '',
        links: ['/admin/setting', '/admin/gallery'],
        items: [
          {
            id: uuidv4(),
            title: 'Order Status',
            href: '/admin/setting'
          },
          {
            id: uuidv4(),
            title: 'Gallery',
            href: '/admin/gallery'
          },
          {
            id: uuidv4(),
            title: 'Booking Settings',
            href: '/admin/booking-settings'
          }
        ]
      }
    ]
  }
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth + '-' + Math.floor(Math.random() * 10 + 1);
  const isParent = item.isParent === undefined ? false : item.isParent;
  const isThreeLevel =
    item.isThreeLevel === undefined ? false : item.isThreeLevel;

  let open = false;

  if (item.items) {
    open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    if (item.links !== undefined) {
      open = item.links.includes(window.location.pathname);
    }

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        isParent={Boolean(isParent)}
        isThreeLevel={Boolean(isThreeLevel)}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        isParent={Boolean(isParent)}
        isThreeLevel={Boolean(isThreeLevel)}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  subHeader: {
    color: 'white'
  },
  changeLogs: {
    color: 'white',
    paddingLeft: 54,
    fontSize: 16
  },
  sidebarWrapper: {
    background: 'linear-gradient(180deg, #3546AB 0%, #1B2B4D 100%)',
    boxShadow:
      '0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)'
  },
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      className={classes.sidebarWrapper}
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Divider />
        <Box p={2}>
          {sections.map(section => (
            <List
              key={`${Math.floor(Math.random() * 10000 + 1)}`}
              subheader={
                <ListSubheader
                  className={classes.subHeader}
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: 'location.pathname'
              })}
            </List>
          ))}
        </Box>
        <Box>
          <Link
            color="inherit"
            component={RouterLink}
            to="/admin/change_logs"
            variant="subtitle2"
          >
            <Typography
              color="textSecondary"
              variant="subtitle2"
              className={classes.changeLogs}
            >
              Change Logs
            </Typography>
          </Link>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
