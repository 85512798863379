import axios from 'axios';
import isValidToken from 'src/utils/isValidToken';
import refreshSession from 'src/utils/refreshSession';
import wait from 'src/utils/wait';

const axiosInstance = axios.create();

// Request interceptor for API call
axiosInstance.interceptors.request.use( async (config) => {
  config.headers.Authorization = localStorage.getItem('accessToken') ? `Bearer ${localStorage.getItem('accessToken')}` : '';
    return config;
  },
  error => {
    Promise.reject(error)
  });

// Response interceptor for API calls
axiosInstance.interceptors.response.use( async (response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    const data = await refreshSession();
    await wait(2500);
    axios.defaults.headers.common['Authorization'] = `Bearer ${data?.access_token}`;
    return axiosInstance(originalRequest);
  }
  return Promise.reject(error);
});

export default axiosInstance;
