import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axiosIn';
import logger from 'src/utils/logger';

const initialState = {
  employees: []
};

const slice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    getEmployees(state, action) {
      const { employees, total } = action.payload;
      state.employees = employees;
      state.total = total;
    },
    addEmployee(state, action) {
      state.employees = [action.payload];
    },
    getEmployee(state, action) {
      const { employees } = action.payload;
      state.employees = employees;
    }
  }
});
export const { addEmployee } = slice.actions;
export const reducer = slice.reducer;

const token = localStorage.getItem('accessToken');

export const getEmployees = (page, limit) => dispatch => {
  const body = {
    page,
    limit
  };

  (async () => {
    await axios
      .post(`${process.env.REACT_APP_BE_EMPLOYEE_URL}/getallemployee`, body)
      .then(res => {
        let obj = JSON.parse(
          JSON.stringify({
            employees: [...res.data.data],
            total: res.data.total
          })
        );
        dispatch(slice.actions.getEmployees(obj));
      });
  })().catch(err => {
    console.error(err);
  });
};

export const getActiveEmployees = (page, limit, n) => dispatch => {
  const body = {
    page,
    limit,
    is_activated: n
  };

  (async () => {
    await axios
      .post(`${process.env.REACT_APP_BE_EMPLOYEE_URL}/getallemployee`, body)
      .then(res => {
        let obj = JSON.parse(
          JSON.stringify({
            employees: [...res.data.data],
            total: res.data.total
          })
        );
        dispatch(slice.actions.getEmployees(obj));
      });
  })().catch(err => {
    console.error(err);
  });
};

export const getQueryEmployees = (page, limit, n,active) => dispatch => {
  const body = {
    page,
    limit,
    search: n,
    is_activated: active
  };

  (async () => {
    await axios
      .post(`${process.env.REACT_APP_BE_EMPLOYEE_URL}/getallemployee`, body)
      .then(res => {
        let obj = JSON.parse(
          JSON.stringify({
            employees: [...res.data.data],
            total: res.data.total
          })
        );
        dispatch(slice.actions.getEmployees(obj));
      });
  })().catch(err => {
    console.error(err);
  });
};

export const editEmployee = (data, id) => dispatch => {
  (async () => {
    alert(data, id, `${process.env.REACT_APP_BE_EMPLOYEE_URL}/${id}`);
    await axios
      .put(`${process.env.REACT_APP_BE_EMPLOYEE_URL}/${id}`, data)
      .then(res => {
        logger('Employee Updated Successfully');
      });
  })().catch(err => {
    logger(err);
  });
};

export default slice;
