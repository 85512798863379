import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'axios';

const initialState = {
  locations: []
};

const slice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    getLocations(state, action) {
      const { locations } = action.payload;
      state.locations = locations;
    },
    addLocation(state, action) {
      state.locations = [action.payload];
    },
    getLocation(state, action) {
      const { locations } = action.payload;
      state.locations = locations;
    }
  }
});
export const { addLocation } = slice.actions;
export const reducer = slice.reducer;

const token = localStorage.getItem('accessToken');

export const getLocation = id => dispatch => {
  (async () => {
    await axios
      .get(`${process.env.REACT_APP_BE_PIM_URL}/location/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => {
        let obj = { locations: JSON.parse(res.data.result) };
        dispatch(slice.actions.getLocation(obj));
      });
  })().catch(err => {
    console.error(err);
  });
};

export const getLocations = () => dispatch => {
  (async () => {
    await axios
      .get(`${process.env.REACT_APP_BE_PIM_URL}/location`, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => {
        let obj = { locations: JSON.parse(res.data.result) };
        dispatch(slice.actions.getLocations(obj));
      });
  })().catch(err => {
    console.error(err);
  });
};

export const editLocation = (data, id) => dispatch => {
  (async () => {
    await axios
      .put(`${process.env.REACT_APP_BE_PIM_URL}/location/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then(res => {});
  })().catch(err => {
    console.error(err);
  });
};

export default slice;
