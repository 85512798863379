import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axiosIn';
import logger from 'src/utils/logger';
import { number } from 'prop-types';

const initialState = {
  organizations: [],
  total: 0
};

const slice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    getOrganizations(state, action) {
      const { organizations, total } = action.payload;
      state.organizations = organizations;
      state.total = total;
    },
    addOrganization(state, action) {
      state.organizations = [action.payload];
    },
    getOrganization(state, action) {
      const { organizations } = action.payload;
      state.organizations = organizations;
    }
  }
});
export const { addOrganization } = slice.actions;
export const reducer = slice.reducer;

export const getOrganizations = (page, limit) => dispatch => {
  const body = {
    page,
    limit
  };

  (async () => {
    await axios
      .post(`${process.env.REACT_APP_BE_ORGANIZATION_URL}/getallorg`, body)
      .then(res => {
        let obj = JSON.parse(
          JSON.stringify({
            organizations: [...res.data.data],
            total: res.data.total
          })
        );
        dispatch(slice.actions.getOrganizations(obj));
      });
  })().catch(err => {
    logger(err);
  });
};

export const getActiveOrganizations = (page, limit, n) => dispatch => {
  const body = {
    page,
    limit,
    is_activated: n
  };

  (async () => {
    await axios
      .post(`${process.env.REACT_APP_BE_ORGANIZATION_URL}/getallorg`, body)
      .then(res => {
        let obj = JSON.parse(
          JSON.stringify({
            organizations: [...res.data.data],
            total: res.data.total
          })
        );
        dispatch(slice.actions.getOrganizations(obj));
      });
  })().catch(err => {
    logger(err);
  });
};

export const getQueryOrganizations = (page, limit, n, active) => dispatch => {
  const body = {
    page,
    limit,
    search: n,
    is_activated: active
  };

  (async () => {
    await axios
      .post(`${process.env.REACT_APP_BE_ORGANIZATION_URL}/getallorg`, body)
      .then(res => {
        let obj = JSON.parse(
          JSON.stringify({
            organizations: [...res.data.data],
            total: res.data.total
          })
        );
        dispatch(slice.actions.getOrganizations(obj));
      });
  })().catch(err => {
    logger(err);
  });
};

export const editOrganization = (data, id) => dispatch => {
  (async () => {
    await axios
      .put(`${process.env.REACT_APP_BE_ORGANIZATION_URL}/${id}`, data)
      .then(res => {
        logger('Organization Updated Successfully');
      });
  })().catch(err => {
    logger(err);
  });
};

export default slice;
