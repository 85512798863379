import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axiosIn';
import logger from 'src/utils/logger';

const initialState = {
  attributes: []
};

const slice = createSlice({
  name: 'attributes',
  initialState,
  reducers: {
    getAttributes(state, action) {
      const { attributes } = action.payload;
      state.attributes = attributes;
    },
    addAttributes(state, action) {
      state.attributes = [action.payload];
    },
    getAttribute(state, action) {
      const { attributes } = action.payload;
      state.attributes = attributes;
    },
    updateAttribute(state, action) {
      state.attributes = state.attributes.map(attribute => {
        if (attribute.id === action.payload.id) attribute = action.payload;
        return attribute;
      });
    }
  }
});
export const { addAttributes, updateAttribute } = slice.actions;
export const reducer = slice.reducer;

export const getAttributes = () => dispatch => {
  (async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BE_BICYCLE_ATTRIBUTES_URL}?limit=10&offset=0`
      )
      .then(res => {
        let obj = { attributes: JSON.parse(JSON.stringify(res.data.data)) };
        dispatch(slice.actions.getAttributes(obj));
      });
  })().catch(err => {
    logger('error', err);
  });
};

export const getAttribute = id => dispatch => {
  (async () => {
    await axios
      .get(`${process.env.REACT_APP_BE_BICYCLE_ATTRIBUTES_URL}${id}`)
      .then(res => {
        let obj = { attributes: JSON.parse(res.data.result) };
        dispatch(slice.actions.getAttribute(obj));
      });
  })().catch(err => {
    logger('error', err);
  });
};

export const editAttribute = data => dispatch => {
  dispatch(slice.actions.updateAttribute(data));
  (async () => {
    await axios
      .put(
        `${process.env.REACT_APP_BE_BICYCLE_ATTRIBUTES_URL}/${data.id}`,
        data
      )
      .then(res => {});
  })().catch(err => {
    logger('error', err);
  });
};

export default slice;
