import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { number } from 'prop-types';
import axios from 'src/utils/axiosIn';
import logger from 'src/utils/logger';

const initialState = {
  bicycleTypes: [],
  total: 0
};

const slice = createSlice({
  name: 'bicycleTypes',
  initialState,
  reducers: {
    getBicycleTypes(state, action) {
      const { bicycleTypes, total } = action.payload;
      state.bicycleTypes = bicycleTypes;
      state.total = total;
    }
  }
});
export const reducer = slice.reducer;

export const getBicycleTypes = (page, limit) => dispatch => {
  const body = {
    page,
    limit
  };

  (async () => {
    await axios
      .post(
        `${process.env.REACT_APP_BE_BICYCLE_TYPE_URL}/getallbicycletype`,
        body
      )
      .then(res => {
        let obj = JSON.parse(
          JSON.stringify({
            bicycleTypes: [...res.data.data],
            total: res.data.total
          })
        );
        dispatch(slice.actions.getBicycleTypes(obj));
      });
  })().catch(err => {
    logger(err);
  });
};

export default slice;
