import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import qs from 'qs';
import axios from 'src/utils/axiosIn';
import logger from 'src/utils/logger';

const initialState = {
    administrators: [],
    total:0
};

const slice = createSlice({
  name: 'administrators',
  initialState,
  reducers: {
    
    getAdministrators(state, action) {
      const { administrators , total } = action.payload;
      state.administrators = administrators;
      state.total   = total;
    },
    addAdministrator(state, action) {
     state.administrators = [action.payload];
    },
    getAdministrator(state, action) {
      const { administrators } = action.payload;
      state.administrators = administrators;
    },
 
  }
});
 export const { addAdministrator } = slice.actions;
export const reducer = slice.reducer;

export const getAdministrators = (page,limit) => (dispatch) => {

    (async () => {
        await axios.post(`${process.env.REACT_APP_BE_USER_URL}/getAlluser`,{
          "limit":limit,
          "page":page,
          "user_type":"Admin",
        })
        .then(res => {
            let obj = JSON.parse(JSON.stringify({administrators:[...res.data.data],total:res.data.total}));
            dispatch(slice.actions.getAdministrators(obj));
        });
    })().catch(err => {
        logger(err);
    });
  };

  export const getActiveAdministrators = (page,limit,n) => (dispatch) => {

    (async () => {
        await axios.post(`${process.env.REACT_APP_BE_USER_URL}/getAlluser`,{
          "limit":limit,
          "page":page,
          "is_activated":n,
          "user_type":"Admin",
        })
        .then(res => {
            let obj = JSON.parse(JSON.stringify({administrators:[...res.data.data],total:res.data.total}));
            dispatch(slice.actions.getAdministrators(obj));
        });
    })().catch(err => {
        logger(err);
    });
  };

  export const getQueryAdministrators = (page,limit,n,active) => (dispatch) => {

    (async () => {
        await axios.post(`${process.env.REACT_APP_BE_USER_URL}/getAlluser`,{
          "limit":limit,
          "page":page,
          "search":n,
          "is_activated":active,
          "user_type":"Admin",
        })
        .then(res => {
            let obj = JSON.parse(JSON.stringify({administrators:[...res.data.data],total:res.data.total}));
            dispatch(slice.actions.getAdministrators(obj));
        });
    })().catch(err => {
        logger(err);
    });
  };

  export const getAdministrator = (id) => (dispatch) => {

    (async () => {
        await axios.post(`${process.env.REACT_APP_BE_USER_URL}/${id}`)
        .then(res => {
            let obj = JSON.parse(JSON.stringify({administrators:[res.data.data],total:1}));
            dispatch(slice.actions.getAdministrators(obj));
        });
    })().catch(err => {
        logger(err);
    });
  };



export default slice;



