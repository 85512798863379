import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'axios'
import parse from 'src/utils/parse';
import moment from 'moment';
import logger from 'src/utils/logger';
import { isUndefined } from 'lodash';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const isValidToken = accessToken => {
  if (!accessToken) {
    return false;
  }

  const currentTime = Date.now() / 1000;

  const decoded = jwtDecode(accessToken);

  if (!decoded?.realm_access?.roles?.includes('admin')) { 
    localStorage.clear();
    window.location.href = '/login';
  }
  let v = decoded.exp > currentTime

  return decoded.exp > currentTime;
};

const isValidSASToken = sasToken => {
  if (!sasToken) {
    return false;
  }

  // Added an extra hour to avoid the case if use doesn't refresh the page for 1 hour
  const extraHour = 60 * 1000;
  const currentTime = Date.now() / 1000;

  return moment(currentTime + extraHour).isAfter(
    parseInt(localStorage.getItem('sasTokenExpiryDate'))
  );
};

const setSession = data => {
  if (!data) {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  } else {
    const { access_token, expires_in, refresh_token } = data;
    if (access_token || expires_in || refresh_token) {
      localStorage.setItem('accessToken', access_token);
      localStorage.setItem('refreshToken', refresh_token);
    }
  }
};

const refreshSession = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
 // alert(refreshToken);
  axios.post(`${process.env.REACT_APP_BE_ADMIN_URL}/refreshToken`,{
    refreshToken: refreshToken
  })
  .then((response)=>{
    response = parse(response);
    if (!isUndefined(response?.data)) { 
      const data = response?.data;
      setSession(data);
    }
    else {
      // Use logger throw new Error('Geef geldige referenties op');
      logger('Reponse data is undefined')
    }
  })
  .catch((error)=>{
    logger(error);
    logger('errororoorror')
  })
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (email, password) => {

    await axios.post(`${process.env.REACT_APP_BE_ADMIN_URL}/loginWithCred`,{
      username: email,
      password: password,
    })
    .then((response)=>{
      response = parse(response);
    
      if (!isUndefined(response?.data)) { 
        const data = response?.data;
        const decoded = jwtDecode(data?.access_token);
        if (decoded?.realm_access?.roles?.includes('admin'))
        { 
          setSession(data);
        }
        else {
          // Use logger throw new Error('Geef geldige referenties op');
          logger('roles mismatch')
        }
      }
      else {
        // Use logger throw new Error('Geef geldige referenties op');
        logger('Reponse data is undefined')
      }
    })
      .catch((error) => {
      // Use logger throw new Error('Geef geldige referenties op');
      logger('Geef geldige referenties op');
    })

  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email, name, password) => {
    const response = await axios.post('/api/account/register', {
      email,
      name,
      password
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const checkAuthToken = async () => {
   // alert('check auth called')
    try {
   
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');

      if (
        window.location.pathname === '/login' &&
        accessToken &&
        isValidToken(accessToken)
      ) {
        window.location.href = '/admin/dashboard';
      }
      else if (window.location.pathname === '/forgot-password' || window.location.pathname?.indexOf('reset-password') !== -1) {
      }
      else if (window.location.pathname !== '/login' && !accessToken) {
        window.location.href = '/login';
      } else if (refreshToken && !isValidToken(accessToken)) {
        refreshSession();
      }

    dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: window.location.pathname !== '/login' || window.location.pathname === '/forgot-password',
          user: null
          
        }
      });
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
      
    }
  };

  useEffect(() => {
    checkAuthToken();

  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (  
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
