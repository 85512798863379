import { createSlice } from '@reduxjs/toolkit';
import logger from 'src/utils/logger';
import _ from 'lodash';
import axios from 'src/utils/axiosIn';
import { number } from 'prop-types';

const initialState = {
    orgTypes: [],
    total:0
};

const slice = createSlice({
  name: 'orgTypes',
  initialState,
  reducers: {
    
    getOrgTypes(state, action) {
      const { orgTypes,total } = action.payload;
      state.orgTypes = orgTypes;
      state.total    = total;
    },
    addOrgType(state, action) {
     state.orgTypes = [action.payload];
    },
    getOrgType(state, action) {
      const { orgTypes } = action.payload;
      state.orgTypes = orgTypes;
    },
  }
});
 export const { addOrgType } = slice.actions;
export const reducer = slice.reducer;

export const getOrgTypes = (page,limit) => (dispatch) => {

  const body={
    page,
    limit
  };

    (async () => {
        await axios.post(`${process.env.REACT_APP_BE_ORGTYPE_URL}/getallorgtype`,body)
        .then(res => {
          let obj = JSON.parse(JSON.stringify({orgTypes:[...res.data.data],total:res.data.total}));
            dispatch(slice.actions.getOrgTypes(obj));
        });
    })().catch(err => {
        logger(err);
    });
  };

  export const getActiveOrgTypes = (page,limit,n) => (dispatch) => {

    const body={
      page,
      limit,
      is_activated: n,
    };
  
      (async () => {
          await axios.post(`${process.env.REACT_APP_BE_ORGTYPE_URL}/getallorgtype`,body)
          .then(res => {
            let obj = JSON.parse(JSON.stringify({orgTypes:[...res.data.data],total:res.data.total}));
              dispatch(slice.actions.getOrgTypes(obj));
          });
      })().catch(err => {
          logger(err);
      });
    };
  
    export const getQueryOrgTypes = (page,limit,n,active) => (dispatch) => {

      const body={
        page,
        limit,
        search: n,
        is_activated: active
      };
    
        (async () => {
            await axios.post(`${process.env.REACT_APP_BE_ORGTYPE_URL}/getallorgtype`,body)
            .then(res => {
              let obj = JSON.parse(JSON.stringify({orgTypes:[...res.data.data],total:res.data.total}));
                dispatch(slice.actions.getOrgTypes(obj));
            });
        })().catch(err => {
            logger(err);
        });
      };

  export const editOrganizationType = (data,id) => (dispatch) => {
    (async () => {
        await axios.put(`${process.env.REACT_APP_BE_ORGTYPE_URL}/${id}`, data)
        .then(res => {
          logger('Organization Type Updated Successfully')
        });
    })().catch(err => { 
      logger(err);
    });
  };



export default slice;

