import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import qs from 'qs';
import axios from 'src/utils/axiosIn';
import logger from 'src/utils/logger';

const initialState = {
    customers: [],
    total:0
};

const slice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    
    getCustomers(state, action) {
      const { customers , total } = action.payload;
      state.customers = customers;
      state.total   = total;
    },
    addCustomer(state, action) {
     state.customers = [action.payload];
    },
    getCustomer(state, action) {
      const { customers } = action.payload;
      state.customers = customers;
    },
 
  }
});
 export const { addCustomer } = slice.actions;
export const reducer = slice.reducer;

export const getCustomers = (page,limit) => (dispatch) => {

    (async () => {
        await axios.post(`${process.env.REACT_APP_BE_USER_URL}/getAlluser`,{
          "limit":limit,
          "page":page,
          "user_type":"Customer",
        })
        .then(res => {
            let obj = JSON.parse(JSON.stringify({customers:[...res.data.data],total:res.data.total}));
            dispatch(slice.actions.getCustomers(obj));
        });
    })().catch(err => {
        logger(err);
    });
  };

  export const getActiveCustomers = (page,limit,n) => (dispatch) => {

    (async () => {
        await axios.post(`${process.env.REACT_APP_BE_USER_URL}/getAlluser`,{
          "limit":limit,
          "page":page,
          "is_activated":n,
          "user_type":"Customer",
        })
        .then(res => {
            let obj = JSON.parse(JSON.stringify({customers:[...res.data.data],total:res.data.total}));
            dispatch(slice.actions.getCustomers(obj));
        });
    })().catch(err => {
        logger(err);
    });
  };

  export const getQueryCustomers = (page,limit,n,active) => (dispatch) => {

    (async () => {
        await axios.post(`${process.env.REACT_APP_BE_USER_URL}/getAlluser`,{
          "limit":limit,
          "page":page,
          "search":n,
          "is_activated":active,
          "user_type":"Customer",
        })
        .then(res => {
            let obj = JSON.parse(JSON.stringify({customers:[...res.data.data],total:res.data.total}));
            dispatch(slice.actions.getCustomers(obj));
        });
    })().catch(err => {
        logger(err);
    });
  };


  export const editCustomer = (data,id) => (dispatch) => {
    (async () => {
        await axios.put(`${process.env.REACT_APP_BE_USER_URL}/${id}`, data)
        .then(res => {
          logger('Customer Updated Successfully')
        });
    })().catch(err => {
        logger(err);
        
    });
  };

  export const getCustomer = (id) => (dispatch) => {

    (async () => {
        await axios.post(`${process.env.REACT_APP_BE_USER_URL}/${id}`)
        .then(res => {
            let obj = JSON.parse(JSON.stringify({customers:[res.data.data],total:1}));
            dispatch(slice.actions.getCustomers(obj));
        });
    })().catch(err => {
        logger(err);
    });
  };




export default slice;



