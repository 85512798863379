import { useSnackbar } from 'notistack';
import {
  useEffect
} from 'react';

const FlashMessage = () => {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const enqueueSnackbarMsgType = localStorage.getItem('enqueueSnackbarMsgType');
	const enqueueSnackbarMsg = localStorage.getItem('enqueueSnackbarMsg');
  closeSnackbar();

  useEffect(() => {
    if (enqueueSnackbarMsgType?.length > 0 && enqueueSnackbarMsg?.length > 0) {
      enqueueSnackbar(
        enqueueSnackbarMsg, {
          variant: enqueueSnackbarMsgType
        },
        10000
      );
      const keysToRemove = ['enqueueSnackbarMsgType', 'enqueueSnackbarMsg'];
      keysToRemove.forEach(k => localStorage.removeItem(k))
    }
  }, []);

  return ('');
};

export default FlashMessage;
