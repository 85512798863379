import axios from 'axios';
import logger from 'src/utils/logger';
import parse from 'src/utils/parse';
import isUndefined from 'lodash';

const refreshSession = async () => {
  const refreshToken = localStorage.getItem('refreshToken');
  axios.post(`${process.env.REACT_APP_BE_ADMIN_URL}/refreshToken`,{
    refreshToken: refreshToken
  })
  .then((response)=>{
    response = parse(response);
    const data = response?.data;
    localStorage.setItem('accessToken', data?.access_token);
    localStorage.setItem('refreshToken', data?.refresh_token);
    return data;
  })
  .catch((error)=>{
    logger(error);
  })
};

export default refreshSession;
