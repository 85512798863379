import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axiosIn';
import logger from 'src/utils/logger';



// export const updateProduct = (productId, data) => dispatch => {
//   (async () => {

//     await axios
//       .put(`${process.env.REACT_APP_BE_PRODUCTS}/${productId}`, data)
//       .then(res => {

//          dispatch(slice.actions.updateProduct(res.data.data));

//       });
//   })().catch(err => {
//     logger(err);
//   });

// }




const initialState = {
  data: [],
};

const productDataSlice = createSlice({
  name: 'productData',
  initialState,
  reducers: {
    editProduct(state, action) {
     
      state.data = action.payload
    },
    updateProduct(state,action) {
      console.log(action, "action")
        state.data.push(action.payload)
    },
    removeProduct(state, action) {
     
    },
  },
});

export const productDataActions = productDataSlice.actions;
export default productDataSlice.reducer;
