import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import NotFoundView from 'src/views/errors/NotFoundView';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={`${i}-${Math.floor(Math.random() * 10 + 1)}`}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
      <Route path="*">
        <NotFoundView />
      </Route>
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/admin/pos/invoice/:id',
    component: lazy(() => import('src/views/pis/pos/InvoicePDF/Invoice'))
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    path: '/admin',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/admin/pos',
        component: lazy(() => import('src/views/pis/pos/AddPos'))
      },
      {
        exact: true,
        path: '/admin/reports',
        component: lazy(() => import('src/views/ProfitReports'))
      },
      {
        exact: true,
        path: '/admin/reports-per-month',
        component: lazy(() => import('src/views/ProfitReportsPerMonth'))
      },
      {
        exact: true,
        path: '/admin/change_logs',
        component: lazy(() => import('src/views/change_log/ChangeLogView'))
      },
      {
        exact: true,
        path: '/admin/dashboard',
        component: lazy(() => import('src/views/dashboard/DashboardView'))
      },
      {
        exact: true,
        path: '/admin/bookings-overview',
        component: lazy(() => import('src/views/calendar'))
      },
      {
        exact: true,
        path: '/admin/bicycle_renting',
        component: lazy(() =>
          import('src/views/bicycle_renting/BicycleRentingListView')
        )
      },
      {
        exact: true,
        path: '/admin/bicycle_renting/create',
        component: lazy(() =>
          import('src/views/bicycle_renting/AddBicycleRenting')
        )
      },
      {
        exact: true,
        path: '/admin/bicycle_renting/view/:id',
        component: lazy(() =>
          import('src/views/bicycle_renting/ViewBicycleRenting')
        )
      },
      {
        exact: true,
        path: '/admin/product-order',
        component: lazy(() =>
          import('src/views/pis/product-order/ProductOrderListView')
        )
      },
      {
        exact: true,
        path: '/admin/product-order/create',
        component: lazy(() =>
          import('src/views/pis/product-order/AddProductOrder')
        )
      },
      {
        exact: true,
        path: '/admin/product-order/view/:id',
        component: lazy(() =>
          import('src/views/pis/product-order/ViewProductOrder')
        )
      },
      {
        exact: true,
        path: '/admin/locale',
        component: lazy(() => import('src/views/locale/LocaleListView'))
      },
      {
        exact: true,
        path: '/admin/locale/create',
        component: lazy(() => import('src/views/locale/AddLocale'))
      },
      {
        exact: true,
        path: '/admin/locale/edit/:id',
        component: lazy(() => import('src/views/locale/EditLocale'))
      },
      {
        exact: true,
        path: '/admin/bicycle_type',
        component: lazy(() =>
          import('src/views/bicycle_type/BicycleTypeListView')
        )
      },
      {
        exact: true,
        path: '/admin/bicycle_type/create',
        component: lazy(() => import('src/views/bicycle_type/AddBicycleType'))
      },
      {
        exact: true,
        path: '/admin/bicycle_type/edit/:id',
        component: lazy(() => import('src/views/bicycle_type/EditBicycleType'))
      },
      {
        exact: true,
        path: '/admin/bicycle_attribute',
        component: lazy(() =>
          import('src/views/bicycle_attribute/BicycleAttributeListView')
        )
      },
      {
        exact: true,
        path: '/admin/bicycle_attribute/create',
        component: lazy(() =>
          import('src/views/bicycle_attribute/AddBicycleAttribute')
        )
      },
      {
        exact: true,
        path: '/admin/bicycle_attribute/edit/:id',
        component: lazy(() =>
          import('src/views/bicycle_attribute/EditBicycleAttribute')
        )
      },
      {
        exact: true,
        path: '/admin/bicycle_frame',
        component: lazy(() =>
          import('src/views/bicycle_frame/BicycleFrameListView')
        )
      },
      {
        exact: true,
        path: '/admin/bicycle_frame/create',
        component: lazy(() => import('src/views/bicycle_frame/AddBicycleFrame'))
      },
      {
        exact: true,
        path: '/admin/bicycle_frame/edit/:id',
        component: lazy(() =>
          import('src/views/bicycle_frame/EditBicycleFrame')
        )
      },
      {
        exact: true,
        path: '/admin/bicycle_attribute_detail',
        component: lazy(() =>
          import(
            'src/views/bicycle_attribute_detail/BicycleAttributeDetailListView'
          )
        )
      },
      {
        exact: true,
        path: '/admin/bicycle_attribute_detail/create',
        component: lazy(() =>
          import('src/views/bicycle_attribute_detail/AddBicycleAttributeDetail')
        )
      },
      {
        exact: true,
        path: '/admin/bicycle_attribute_detail/edit/:id',
        component: lazy(() =>
          import(
            'src/views/bicycle_attribute_detail/EditBicycleAttributeDetail'
          )
        )
      },
      {
        exact: true,
        path: '/admin/employee',
        component: lazy(() => import('src/views/employee/EmployeeListView'))
      },
      {
        exact: true,
        path: '/admin/employee/create',
        component: lazy(() => import('src/views/employee/AddEmployee'))
      },
      {
        exact: true,
        path: '/admin/employee/edit/:id',
        component: lazy(() => import('src/views/employee/EditEmployee'))
      },
      {
        exact: true,
        path: '/admin/tour',
        component: lazy(() => import('src/views/tour/TourListView'))
      },
      {
        exact: true,
        path: '/admin/tour/create',
        component: lazy(() => import('src/views/tour/AddTour'))
      },
      {
        exact: true,
        path: '/admin/tour/edit/:id',
        component: lazy(() => import('src/views/tour/EditTour'))
      },
      {
        exact: true,
        path: '/admin/event_renting',
        component: lazy(() =>
          import('src/views/event_renting/EventRentingListView')
        )
      },
      {
        exact: true,
        path: '/admin/event_renting/create',
        component: lazy(() => import('src/views/event_renting/AddEventRenting'))
      },
      {
        exact: true,
        path: '/admin/event_renting/view/:id',
        component: lazy(() =>
          import('src/views/event_renting/ViewEventRenting')
        )
      },
      {
        exact: true,
        path: '/admin/clinic',
        component: lazy(() => import('src/views/clinic/ClinicListView'))
      },
      {
        exact: true,
        path: '/admin/clinic/create',
        component: lazy(() => import('src/views/clinic/AddClinic'))
      },
      {
        exact: true,
        path: '/admin/clinic/edit/:id',
        component: lazy(() => import('src/views/clinic/EditClinic'))
      },
      {
        exact: true,
        path: '/admin/clinic_booking',
        component: lazy(() =>
          import('src/views/clinic_booking/ClinicBookingListView')
        )
      },
      {
        exact: true,
        path: '/admin/clinic_booking/create',
        component: lazy(() =>
          import('src/views/clinic_booking/AddClinicBooking')
        )
      },
      {
        exact: true,
        path: '/admin/clinic_booking/view/:id',
        component: lazy(() =>
          import('src/views/clinic_booking/ViewClinicBooking')
        )
      },
      {
        exact: true,
        path: '/admin/tag/edit/:id',
        component: lazy(() => import('src/views/tags/EditTag'))
      },
      {
        exact: true,
        path: '/admin/tag',
        component: lazy(() => import('src/views/tags/TagListOverview'))
      },
      {
        exact: true,
        path: '/admin/tag/create',
        component: lazy(() => import('src/views/tags/AddTag'))
      },
      {
        exact: true,
        path: '/admin/organization/edit/:id',
        component: lazy(() => import('src/views/organization/EditOrganization'))
      },
      {
        exact: true,
        path: '/admin/organization',
        component: lazy(() =>
          import('src/views/organization/OrganizationListView')
        )
      },
      {
        exact: true,
        path: '/admin/organization/create',
        component: lazy(() => import('src/views/organization/AddOrganization'))
      },
      {
        exact: true,
        path: '/admin/organization-type',
        component: lazy(() =>
          import('src/views/organization_type/OrganizationTypeListView')
        )
      },
      {
        exact: true,
        path: '/admin/organization-type/create',
        component: lazy(() =>
          import('src/views/organization_type/AddOrganizationType')
        )
      },
      {
        exact: true,
        path: '/admin/organization-type/edit/:id',
        component: lazy(() =>
          import('src/views/organization_type/EditOrganizationType')
        )
      },
      {
        exact: true,
        path: '/admin/client/edit/:id',
        component: lazy(() => import('src/views/client/EditClient'))
      },
      {
        exact: true,
        path: '/admin/client',
        component: lazy(() => import('src/views/client/ClientListView'))
      },
      {
        exact: true,
        path: '/admin/client/create',
        component: lazy(() => import('src/views/client/AddClient'))
      },
      {
        exact: true,
        path: '/admin/setting',
        component: lazy(() => import('src/views/setting/OrderStatusListView'))
      },
      {
        exact: true,
        path: '/admin/setting/create',
        component: lazy(() => import('src/views/setting/AddOrderStatus'))
      },
      {
        exact: true,
        path: '/admin/setting/edit/:id',
        component: lazy(() => import('src/views/setting/EditOrderStatus'))
      },
      {
        exact: true,
        path: '/admin/booking-settings',
        component: lazy(() =>
          import('src/views/booking_settings/BookingSettingsView')
        )
      },
      {
        exact: true,
        path: '/admin/role/edit/:id',
        component: lazy(() => import('src/views/role/EditRole'))
      },
      {
        exact: true,
        path: '/admin/role',
        component: lazy(() => import('src/views/role/RoleListView'))
      },
      {
        exact: true,
        path: '/admin/role/create',
        component: lazy(() => import('src/views/role/AddRole'))
      },
      {
        exact: true,
        path: '/admin/administrator/edit/:id',
        component: lazy(() =>
          import('src/views/administrator/EditAdministrator')
        )
      },
      {
        exact: true,
        path: '/admin/administrator',
        component: lazy(() =>
          import('src/views/administrator/AdministratorListView')
        )
      },
      {
        exact: true,
        path: '/admin/administrator/create',
        component: lazy(() =>
          import('src/views/administrator/AddAdministrator')
        )
      },
      {
        exact: true,
        path: '/admin/customer/edit/:id',
        component: lazy(() => import('src/views/customer/EditCustomer'))
      },
      {
        exact: true,
        path: '/admin/customer',
        component: lazy(() => import('src/views/customer/CustomerListView'))
      },
      {
        exact: true,
        path: '/admin/customer/create',
        component: lazy(() => import('src/views/customer/AddCustomer'))
      },

      // Normal Service, Category and Appointment
      {
        exact: true,
        path: '/admin/service/create',
        component: lazy(() => import('src/views/service/AddService'))
      },
      {
        exact: true,
        path: '/admin/service/edit/:id',
        component: lazy(() => import('src/views/service/EditService'))
      },
      {
        exact: true,
        path: '/admin/service',
        component: lazy(() => import('src/views/service/ServiceListView'))
      },
      {
        exact: true,
        path: '/admin/service-category/create',
        component: lazy(() =>
          import('src/views/serviceCategory/AddServiceCategory')
        )
      },
      {
        exact: true,
        path: '/admin/service-category/edit/:id',
        component: lazy(() =>
          import('src/views/serviceCategory/EditServiceCategory')
        )
      },
      {
        exact: true,
        path: '/admin/service-category',
        component: lazy(() =>
          import('src/views/serviceCategory/ServiceCategoryListView')
        )
      },
      {
        exact: true,
        path: '/admin/appointment/edit/:id',
        component: lazy(() => import('src/views/appointment/EditAppointment'))
      },
      {
        exact: true,
        path: '/admin/appointment',
        component: lazy(() =>
          import('src/views/appointment/AppointmentListView')
        )
      },

      // Bike Fit Section
      {
        exact: true,
        path: '/admin/bike-fit-service/create',
        component: lazy(() => import('src/views/bike_fit_service/AddService'))
      },
      {
        exact: true,
        path: '/admin/bike-fit-service/edit/:id',
        component: lazy(() => import('src/views/bike_fit_service/EditService'))
      },
      {
        exact: true,
        path: '/admin/bike-fit-service',
        component: lazy(() =>
          import('src/views/bike_fit_service/ServiceListView')
        )
      },
      {
        exact: true,
        path: '/admin/bike-fit-appointment/edit/:id',
        component: lazy(() =>
          import('src/views/bike_fit_appointment/EditAppointment')
        )
      },
      {
        exact: true,
        path: '/admin/bike-fit-appointment',
        component: lazy(() =>
          import('src/views/bike_fit_appointment/AppointmentListView')
        )
      },

      {
        exact: true,
        path: '/admin/product-tax/edit/:id',
        component: lazy(() => import('src/views/pis/tax/EditTax'))
      },
      {
        exact: true,
        path: '/admin/product-tax',
        component: lazy(() => import('src/views/pis/tax/TaxListView'))
      },
      {
        exact: true,
        path: '/admin/product-tax/create',
        component: lazy(() => import('src/views/pis/tax/AddTax'))
      },
      {
        exact: true,
        path: '/admin/product-attribute/edit/:id',
        component: lazy(() =>
          import('src/views/pis/productAttribute/EditProductAttribute')
        )
      },
      {
        exact: true,
        path: '/admin/product-attribute',
        component: lazy(() =>
          import('src/views/pis/productAttribute/ProductAttributeListView')
        )
      },
      {
        exact: true,
        path: '/admin/product-attribute/create',
        component: lazy(() =>
          import('src/views/pis/productAttribute/AddProductAttribute')
        )
      },
      {
        exact: true,
        path: '/admin/product-attribute-detail/edit/:id',
        component: lazy(() =>
          import(
            'src/views/pis/productAttributeDetail/EditProductAttributeDetails'
          )
        )
      },
      {
        exact: true,
        path: '/admin/product-attribute-detail',
        component: lazy(() =>
          import(
            'src/views/pis/productAttributeDetail/ProductAttributeDetailsListView'
          )
        )
      },
      {
        exact: true,
        path: '/admin/product-stock',
        component: lazy(() =>
          import('src/views/pis/productStock/ProductStockListView')
        )
      },
      {
        exact: true,
        path: '/admin/product-attribute-details/create',
        component: lazy(() =>
          import(
            'src/views/pis/productAttributeDetail/AddProductAttributeDetails'
          )
        )
      },
      {
        exact: true,
        path: '/admin/product-tag/edit/:id',
        component: lazy(() => import('src/views/pis/tag/EditTag'))
      },
      {
        exact: true,
        path: '/admin/product-tag',
        component: lazy(() => import('src/views/pis/tag/TagListView'))
      },
      {
        exact: true,
        path: '/admin/product-tag/create',
        component: lazy(() => import('src/views/pis/tag/AddTag'))
      },
      {
        exact: true,
        path: '/admin/brand',
        component: lazy(() => import('src/views/pis/brand/BrandListView'))
      },
      {
        exact: true,
        path: '/admin/brand/create',
        component: lazy(() => import('src/views/pis/brand/AddBrand'))
      },
      {
        exact: true,
        path: '/admin/brand/edit/:id',
        component: lazy(() => import('src/views/pis/brand/EditBrand'))
      },
      {
        exact: true,
        path: '/admin/supplier',
        component: lazy(() => import('src/views/pis/supplier/SupplierListView'))
      },
      {
        exact: true,
        path: '/admin/supplier/create',
        component: lazy(() => import('src/views/pis/supplier/AddSupplier'))
      },
      {
        exact: true,
        path: '/admin/supplier/edit/:id',
        component: lazy(() => import('src/views/pis/supplier/EditSupplier'))
      },
      {
        exact: true,
        path: '/admin/catalog/edit/:id',
        component: lazy(() => import('src/views/catalog/EditCatalog'))
      },
      {
        exact: true,
        path: '/admin/catalog',
        component: lazy(() => import('src/views/catalog/CatalogListOverview'))
      },
      {
        exact: true,
        path: '/admin/catalog/create',
        component: lazy(() => import('src/views/catalog/AddCatalog'))
      },
      {
        exact: true,
        path: '/admin/location/edit/:id',
        component: lazy(() => import('src/views/pis/location/EditLocation'))
      },
      {
        exact: true,
        path: '/admin/location',
        component: lazy(() => import('src/views/pis/location/LocationListView'))
      },
      {
        exact: true,
        path: '/admin/location/create',
        component: lazy(() => import('src/views/pis/location/AddLocation'))
      },
      {
        exact: true,
        path: '/admin/product-category/edit/:id',
        component: lazy(() => import('src/views/pis/category/EditCategory'))
      },
      {
        exact: true,
        path: '/admin/product-category',
        component: lazy(() => import('src/views/pis/category/CategoryListView'))
      },
      {
        exact: true,
        path: '/admin/product-category/create',
        component: lazy(() => import('src/views/pis/category/AddCategory'))
      },
      {
        exact: true,
        path: '/admin/product/edit/:id',
        component: lazy(() => import('src/views/pis/product/EditProduct'))
      },
      {
        exact: true,
        path: '/admin/product',
        component: lazy(() => import('src/views/pis/product/ProductListView'))
      },
      {
        exact: true,
        path: '/admin/product/create',
        component: lazy(() => import('src/views/pis/product/AddProduct'))
      },
      {
        exact: true,
        path: '/admin/gallery/edit/:id',
        component: lazy(() => import('src/views/gallery/EditGalleryImage'))
      },
      {
        exact: true,
        path: '/admin/gallery',
        component: lazy(() => import('src/views/gallery/GalleryListView'))
      },
      {
        exact: true,
        path: '/admin/gallery/create',
        component: lazy(() => import('src/views/gallery/AddGalleryImage'))
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('src/views/auth/LoginView'))
      }
      // {
      //   component: () => <Redirect to="/404" />
      // }
    ]
  }
];

export default routes;
