import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axiosIn';
import logger from 'src/utils/logger';

const initialState = {
  bicycleFrames: [],
  total: 0
};

const slice = createSlice({
  name: 'bicycleFrames',
  initialState,
  reducers: {
    getBicycleFrames(state, action) {
      const { bicycleFrames, total } = action.payload;
      state.bicycleFrames = bicycleFrames;
      state.total = total;
    }
  }
});
export const reducer = slice.reducer;

export const getBicycleFrames = (page, limit) => dispatch => {
  const body = {
    page,
    limit
  };

  (async () => {
    await axios
      .post(`${process.env.REACT_APP_BE_BICYCLE_FRAME_URL}/getallframe`, body)
      .then(res => {
        let obj = JSON.parse(
          JSON.stringify({
            bicycleFrames: [...res.data.data],
            total: res.data.total
          })
        );
        dispatch(slice.actions.getBicycleFrames(obj));
      });
  })().catch(err => {
    logger(err);
  });
};

export default slice;
