import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axiosIn';
import logger from 'src/utils/logger';

const initialState = {
  tags: []
};

const slice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    getTags(state, action) {
      const { tags, total } = action.payload;
      state.tags = tags;
      state.total = total;
    },
    addTags(state, action) {
      state.tags = [action.payload];
    },
    getTag(state, action) {
      const { tags } = action.payload;
      state.tags = tags;
    }
  }
});
export const { addTags, updateTag } = slice.actions;
export const reducer = slice.reducer;

export const getTags = (page, limit) => dispatch => {
  const body = {
    page,
    limit
  };

  (async () => {
    await axios
      .post(`${process.env.REACT_APP_BE_TAG_URL}/getalltag`, body)
      .then(res => {
        let obj = JSON.parse(
          JSON.stringify({ tags: [...res.data.data], total: res.data.total })
        );
        dispatch(slice.actions.getTags(obj));
      });
  })().catch(err => {
    console.error(err);
  });
};

export const getActiveTags = (page, limit, n) => dispatch => {
  const body = {
    page,
    limit,
    is_activated: n
  };

  (async () => {
    await axios
      .post(`${process.env.REACT_APP_BE_TAG_URL}/getalltag`, body)
      .then(res => {
        let obj = JSON.parse(
          JSON.stringify({ tags: [...res.data.data], total: res.data.total })
        );
        dispatch(slice.actions.getTags(obj));
      });
  })().catch(err => {
    console.error(err);
  });
};

export const getQueryTags = (page, limit, n, active) => dispatch => {
  const body = {
    page,
    limit,
    search: n,
    is_activated: active
  };

  (async () => {
    await axios
      .post(`${process.env.REACT_APP_BE_TAG_URL}/getalltag`, body)
      .then(res => {
        let obj = JSON.parse(
          JSON.stringify({ tags: [...res.data.data], total: res.data.total })
        );
        dispatch(slice.actions.getTags(obj));
      });
  })().catch(err => {
    console.error(err);
  });
};

export const getTag = id => dispatch => {
  (async () => {
    await axios.get(`${process.env.REACT_APP_BE_TAG_URL}/${id}`).then(res => {
      let obj = { tags: JSON.parse(res.data.result) };
      dispatch(slice.actions.getTag(obj));
    });
  })().catch(err => {
    console.error(err);
  });
};

export const editTag = (data, id) => dispatch => {
  (async () => {
    await axios
      .put(`${process.env.REACT_APP_BE_TAG_URL}/${id}`, data)
      .then(res => {
        logger('Client Updated Successfully');
      });
  })().catch(err => {
    logger(err);
  });
};

export default slice;
